<template>
    <div>
        <div class="demo-spin-article">
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="fomr-label col-label required">{{
                        $t('pensionRate.dateForm')
                    }}</label>
                    <div class="col-md-6">
                        <DatePicker
                            :value="model.date_from"
                            type="date"
                            format="dd-MM-yyyy"
                            placeholder="Select date"
                            style="width: 237px"
                            @on-change="date => (model.date_from = date)"
                            :class="{
                                'is-invalid': errors.has('date_from')
                            }"
                        ></DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('date_from')"
                        >
                            {{ errors.first('date_from') }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="fomr-label col-label required">{{
                        $t('pensionRate.dateTo')
                    }}</label>
                    <div class="col-md-6">
                        <DatePicker
                            :value="model.date_to"
                            type="date"
                            format="dd-MM-yyyy"
                            placeholder="Select date"
                            style="width: 237px"
                            :class="{
                                'is-invalid': errors.has('date_to')
                            }"
                            @on-change="date => (model.date_to = date)"
                        ></DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('date_to')"
                        >
                            {{ errors.first('date_to') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-label col-label required">{{
                        $t('pensionRate.employeeContributionRate')
                    }}</label>
                    <div class="tw-whitespace-nowrap">
                        <Poptip trigger="focus">
                            <Input
                                v-model="model.employee_contribution_rate"
                                type="number"
                                :placeholder="$t('pensionRate.enterNumber')"
                                style="width: 136%"
                                ref="employee_contribution_rate"
                                :class="{
                                    'is-invalid': errors.has(
                                        'employee_contribution_rate'
                                    )
                                }"
                            >
                            </Input>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('employee_contribution_rate')"
                            >
                                {{ errors.first('employee_contribution_rate') }}
                            </div>
                            <div slot="content">
                                {{
                                    model.employee_contribution_rate
                                        ? formatNumber(
                                              model.employee_contribution_rate
                                          )
                                        : $t('pensionRate.enterNumber')
                                }}
                            </div>
                        </Poptip>
                    </div>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('employee_contribution_rate')"
                    >
                        {{ errors.first('employee_contribution_rate') }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-label col-label required">{{
                        $t('pensionRate.employerContributionRate')
                    }}</label>
                    <div class="tw-whitespace-nowrap">
                        <Poptip trigger="focus">
                            <Input
                                style="width: 136%"
                                type="number"
                                :placeholder="$t('pensionRate.enterNumber')"
                                v-model="model.employer_contribution_rate"
                                :class="{
                                    'is-invalid': errors.has(
                                        'employer_contribution_rate'
                                    )
                                }"
                            >
                            </Input>
                            <div slot="content">
                                {{
                                    model.wage_from
                                        ? formatNumber(
                                              model.employer_contribution_rate
                                          )
                                        : $t('pensionRate.enterNumber')
                                }}
                            </div>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('employer_contribution_rate')"
                            >
                                {{ errors.first('employer_contribution_rate') }}
                            </div>
                        </Poptip>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label class="form-label col-label">{{
                    $t('pensionRate.remark')
                }}</label>
                <textarea
                    v-model="model.remark"
                    class="form-control from-label"
                    rows="5"
                >
                </textarea>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}
                    </ts-button>
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onUpdate"
                    >
                        {{ $t('update') }}
                    </ts-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'pension-rate-form-create',
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            model: {
                date_from: null,
                date_to: null,
                employee_contribution_rate: null,
                employer_contribution_rate: null,
                remark: null
            }
        }
    },
    created () {
        this.setEditData()
    },
    computed: {
        ...mapState('payroll/pensionRate', ['edit_data']),
        ...mapGetters(['formatNumber']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/pensionRate/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/pensionRate/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/pensionRate/update', {
                    id: this.edit_data.id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.date_from = this.edit_data.date_from
                this.model.date_to = this.edit_data.date_to
                this.model.employee_contribution_rate =
                    this.edit_data.employee_contribution_rate
                this.model.employer_contribution_rate =
                    this.edit_data.employer_contribution_rate
                this.model.remark = this.edit_data.remark
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.date_from = null
            this.model.date_to = null
            this.model.employee_contribution_rate = null
            this.model.employer_contribution_rate = null
            this.model.remark = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PENSION RATE',
                desc: not.text
            })
        }
    }
}
</script>
